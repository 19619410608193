import React from "react";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";

const Experience = () => {
    return (
        <section id="experience" className="bg-darkgray">
            <div className="container">
                <h2 className="highlight">
                    <i className="fa fa-briefcase icon-3d fa-3dicon text-lightblue me-2"></i>{" "}
                    Experience
                </h2>
                <div className="accordion" id="experience-accordion">
                    <div className="accordion-item">
                        <div className="accordion-header" id="headingOne">
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                <div className=" d-flex gap-3 flex-column flex-lg-row align-items-center ">
                                    <span className="badge bg-dark p-2">
                                        04/2022 - Present
                                    </span>
                                    <h4 className="p-0 m-0 text-center">
                                        Tasmanian Berries
                                    </h4>
                                    <p className=" p-0 m-0 text-center text-gray">
                                        Data Analyst & Web Developer
                                    </p>
                                </div>
                            </button>
                            <span className="border_btm"></span>
                        </div>
                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse show border-corners"
                            aria-labelledby="headingOne"
                            data-bs-parent="#experience-accordion"
                        >
                            <div className="accordion-body text-gray p-4 p-md-5 bg-dark">
                                <ul>
                                    <li>
                                        Captured and analysed data with a focus
                                        on labour costs and performance of 350+
                                        seasonal workers. Used data and
                                        analytics skills to help the company
                                        make a decision that saved{" "}
                                        <span className="text-blue">
                                            $1,044,598 (12.88%)
                                        </span>{" "}
                                        in labour costs.
                                    </li>
                                    <li>
                                        Developed a system of Google
                                        spreadsheets, insightful dashboards, and
                                        automated email notifications that
                                        provide real-time updates and help the
                                        management team to forecast and respond
                                        quickly to all incidents.
                                    </li>
                                    <li>
                                        Designed a planning system that assisted
                                        the board of directors make decisions
                                        for a 5 year plan where the company
                                        would double its crop area in multiple
                                        states.
                                    </li>
                                    <li>
                                        Collaborated with all other departments
                                        (e.g. HR, Finance, Harvest,...), used
                                        multiple data and coding skills to
                                        provide effective solutions to solve
                                        their problems hence reducing their
                                        workload.
                                    </li>
                                    <li>
                                        Created a number of in-house web
                                        applications using JavaScript, CSS,
                                        Bootstrap, Google Apps Scripts and
                                        Google Sheets API that facilitated
                                        effective communication and labour
                                        management of the company. Helped to
                                        increase{" "}
                                        <span className="text-blue">18%</span>{" "}
                                        in seasonal workers’s productivity.
                                    </li>
                                </ul>
                            </div>
                            <span className="border_btm"></span>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <div className="accordion-header" id="headingTwo">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                            >
                                <div className=" d-flex gap-3 flex-column flex-lg-row align-items-center">
                                    <span className="badge bg-dark p-2">
                                        06/2021 - 04/2022
                                    </span>
                                    <h4 className="p-0 m-0 text-center">
                                        Global Evergreening Alliance
                                    </h4>
                                    <p className=" p-0 m-0 text-center text-gray">
                                        Volunteer Spatial Data Management
                                        Officer
                                    </p>
                                </div>
                            </button>
                        </div>
                        <div
                            id="collapseTwo"
                            className="accordion-collapse collapse border-corners"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#experience-accordion"
                        >
                            <div className="accordion-body text-gray p-4 p-md-5 bg-dark">
                                <ul>
                                    <li>
                                        Helped with the management of spatial
                                        data for 200+ global land restoration
                                        projects on ArcGIS Online. Created a
                                        simple web API for the data using Flask.
                                    </li>
                                    <li>
                                        Created a{" "}
                                        <a
                                            href="https://earlybird-dev.github.io/GRM/"
                                            className="text-lightblue"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            website
                                        </a>{" "}
                                        which allowed the integration of an
                                        advanced interactive map and project
                                        profiles.
                                    </li>
                                </ul>
                            </div>
                            <span className="border_btm"></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Experience;
