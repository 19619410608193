import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";

function App() {
  function HomePage() {
    return (
      <>
        <Header />
        <Main />
        <Footer />
      </>
    );
  }

  function Datathon2023() {
    return (
      <>
        <iframe
          title="datathon2023-report"
          src="https://lookerstudio.google.com/embed/reporting/166663eb-58dd-4f50-9928-bc5d727935c9/page/p_tk653xnkad"
          frameBorder={0}
          style={{
            overflow: "hidden",
            overflowX: "hidden",
            overflowY: "hidden",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          height="100%"
          width="100%"
        />
      </>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/datathon2023" element={<Datathon2023 />} />
    </Routes>
  );
}

export default App;
